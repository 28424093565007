import GUI from 'lil-gui'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Textures
 */
const bakedATexture = textureLoader.load('bakedA-min.webp')
bakedATexture.flipY = false
bakedATexture.colorSpace = THREE.SRGBColorSpace

const bakedBTexture = textureLoader.load('bakedB-min.webp')
bakedBTexture.flipY = false
bakedBTexture.colorSpace = THREE.SRGBColorSpace

const screenTexture = textureLoader.load('fond-ecran-min.webp')
screenTexture.flipY = false
screenTexture.colorSpace = THREE.SRGBColorSpace

const windowTexture = textureLoader.load('windows-min.webp')
windowTexture.flipY = false
windowTexture.colorSpace = THREE.SRGBColorSpace

/**
 * Materials
 */
// Baked material
const bakedAMaterial = new THREE.MeshBasicMaterial({ map: bakedATexture })
const bakedBMaterial = new THREE.MeshBasicMaterial({ map: bakedBTexture })

const LampLightMaterial = new THREE.MeshBasicMaterial({ color: 0xffffaa })
const OrdiScreenLightMaterial = new THREE.MeshStandardMaterial({ map: screenTexture, emissiveMap: screenTexture, emissiveIntensity: 2, emissive: new THREE.Color(0xffffff), })
const WindowLightMaterial = new THREE.MeshStandardMaterial({ map: windowTexture, emissiveMap: windowTexture, emissiveIntensity: 2, emissive: new THREE.Color(0xffffff), })
const GlassMaterial = new THREE.MeshStandardMaterial({
    color: 0x00ff00,
    transparent: true,
    opacity: 0.5,
    roughness: 0.1,
    metalness: 0.1
});


/**
 * Model
 */
gltfLoader.load(
    'open-space-4.glb',
    (gltf) =>
    {

        const bakedAMesh = gltf.scene.children.find(child => child.name === 'bakedA')
        const bakedBMesh = gltf.scene.children.find(child => child.name === 'bakedB')
        const LampLightMesh = gltf.scene.children.find(child => child.name === 'lampA')
        const Lamp001LightMesh = gltf.scene.children.find(child => child.name === 'lampB')
        const OrdiScreenLightMesh = gltf.scene.children.find(child => child.name === 'ordi-screenA')
        const OrdiScreen001LightMesh = gltf.scene.children.find(child => child.name === 'ordi-screenB')
        const OrdiScreen002LightMesh = gltf.scene.children.find(child => child.name === 'ordi-screenC')
        const OrdiScreen003LightMesh = gltf.scene.children.find(child => child.name === 'ordi-screenD')
        const OrdiScreen004LightMesh = gltf.scene.children.find(child => child.name === 'ordi-screenE')
        const OrdiScreen005LightMesh = gltf.scene.children.find(child => child.name === 'ordi-screenF')
        const WindowLightMesh = gltf.scene.children.find(child => child.name === 'windowA')
        const Window001LightMesh = gltf.scene.children.find(child => child.name === 'windowB')
        const Window002LightMesh = gltf.scene.children.find(child => child.name === 'windowC')
        const GlassMesh = gltf.scene.children.find(child => child.name === 'glassA')
        const Glass001Mesh = gltf.scene.children.find(child => child.name === 'glassB')
        const Glass002Mesh = gltf.scene.children.find(child => child.name === 'glassC')
        const Glass003Mesh = gltf.scene.children.find(child => child.name === 'glassD')
        const Glass004Mesh = gltf.scene.children.find(child => child.name === 'glassE')
        const Glass005Mesh = gltf.scene.children.find(child => child.name === 'glassF')
        const Glass006Mesh = gltf.scene.children.find(child => child.name === 'glassG')
        const Glass007Mesh = gltf.scene.children.find(child => child.name === 'glassH')

        bakedAMesh.material = bakedAMaterial
        bakedBMesh.material = bakedBMaterial

        LampLightMesh.material = LampLightMaterial
        Lamp001LightMesh.material = LampLightMaterial
        OrdiScreenLightMesh.material = OrdiScreenLightMaterial
        OrdiScreen001LightMesh.material = OrdiScreenLightMaterial
        OrdiScreen002LightMesh.material = OrdiScreenLightMaterial
        OrdiScreen003LightMesh.material = OrdiScreenLightMaterial
        OrdiScreen004LightMesh.material = OrdiScreenLightMaterial
        OrdiScreen005LightMesh.material = OrdiScreenLightMaterial
        WindowLightMesh.material = WindowLightMaterial
        Window001LightMesh.material = WindowLightMaterial
        Window002LightMesh.material = WindowLightMaterial

        GlassMesh.material = GlassMaterial
        Glass001Mesh.material = GlassMaterial
        Glass002Mesh.material = GlassMaterial
        Glass003Mesh.material = GlassMaterial
        Glass004Mesh.material = GlassMaterial
        Glass005Mesh.material = GlassMaterial
        Glass006Mesh.material = GlassMaterial
        Glass007Mesh.material = GlassMaterial
        

        scene.add(gltf.scene)
    }
)

const mixers = []


gltfLoader.load(
    'plante-bis.glb',
    (gltf) =>
    {

        scene.add(gltf.scene)
        
        gltf.scene.traverse((node) => {
            if (node.isMesh) {
                node.material = bakedBMaterial;
            }
        });
        
        // Créer un AnimationMixer pour gérer les animations du modèle
        const mixer = new THREE.AnimationMixer(gltf.scene);

        // Accéder aux animations du modèle
        const animations = gltf.animations;

        // Vérifier si des animations sont présentes
        if (animations && animations.length) {
            // Boucle pour ajouter toutes les animations au mixer
            animations.forEach((clip) => {
                const action = mixer.clipAction(clip);
                action.play(); // Jouer chaque animation
            });
        }

        mixers.push(mixer)
    },
    undefined,
    (error) => {
        console.error('Erreur lors du chargement du modèle GLTF:', error);
    }
);

gltfLoader.load(
    'perso-2-bis-bis.glb',
    (gltf) =>
    {

        scene.add(gltf.scene);

        // Appliquer le matériau à tous les maillages
        gltf.scene.traverse((node) => {
            if (node.isMesh) {
                node.material = bakedBMaterial;
            }
        });

        // Créer un AnimationMixer pour gérer les animations du modèle
        const mixer = new THREE.AnimationMixer(gltf.scene);

        // Accéder aux animations du modèle
        const animations = gltf.animations;

        console.log(animations)



        // Vérifier si des animations sont présentes
        if (animations && animations.length) {
            // Jouer la première animation (ou celle que vous souhaitez)
            const action = mixer.clipAction(animations[0]);
            action.setLoop(THREE.LoopOnce);
            action.clampWhenFinished = true;
            action.enable = true;
            action.play();

            // Mettre l'animation à la première frame pour définir la pose initiale
            mixer.setTime(0);
            // action.paused = true; // Pauser l'animation pour maintenir la pose initiale

            loop(action)
        }

        mixers.push(mixer)
    },
    undefined,
    (error) => {
        console.error('Erreur lors du chargement du modèle GLTF:', error);
    }
);

gltfLoader.load(
    'perso-1-bis-bis.glb',
    (gltf) =>
    {

        scene.add(gltf.scene);

        // Appliquer le matériau à tous les maillages
        gltf.scene.traverse((node) => {
            if (node.isMesh) {
                node.material = bakedBMaterial;
            }
        });

        // Créer un AnimationMixer pour gérer les animations du modèle
        const mixer = new THREE.AnimationMixer(gltf.scene);

        // Accéder aux animations du modèle
        const animations = gltf.animations;


        // Vérifier si des animations sont présentes
        if (animations && animations.length) {
            // Jouer la première animation (ou celle que vous souhaitez)
            const action = mixer.clipAction(animations[0]);
            action.setLoop(THREE.LoopOnce);
            action.clampWhenFinished = true;
            action.enable = true;
            action.play();

            // Mettre l'animation à la première frame pour définir la pose initiale
            mixer.setTime(0);
            // action.paused = true; // Pauser l'animation pour maintenir la pose initiale

            loop(action)
        }

        mixers.push(mixer)
    },
    undefined,
    (error) => {
        console.error('Erreur lors du chargement du modèle GLTF:', error);
    }
);


gltfLoader.load(
    'perso-3.glb',
    (gltf) =>
    {

        scene.add(gltf.scene);

        // Appliquer le matériau à tous les maillages
        gltf.scene.traverse((node) => {
            if (node.isMesh) {
                node.material = bakedBMaterial;
            }
        });

        // Créer un AnimationMixer pour gérer les animations du modèle
        const mixer = new THREE.AnimationMixer(gltf.scene);

        // Accéder aux animations du modèle
        const animations = gltf.animations;

        // Vérifier si des animations sont présentes
        if (animations && animations.length) {
            // Jouer la première animation (ou celle que vous souhaitez)
            const action = mixer.clipAction(animations[2]);
            action.setLoop(THREE.LoopOnce);
            action.clampWhenFinished = true;
            action.enable = true;
            action.play();

            // Mettre l'animation à la première frame pour définir la pose initiale
            mixer.setTime(0);
            // action.paused = true; // Pauser l'animation pour maintenir la pose initiale
            loop(action)
        }

        mixers.push(mixer)
    },
    undefined,
    (error) => {
        console.error('Erreur lors du chargement du modèle GLTF:', error);
    }
);

const loop = (action) => {
    setTimeout(() => {
        // Réinitialiser et rejouer l'animation
        action.reset().play();
        loop(action)
    }, 2500 + Math.random() * 7000);
}

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
// Définir les tailles de la vue
const aspect = sizes.width / sizes.height;

// Définir le frustumSize en fonction de l'appareil
const isMobile = window.innerWidth < 768; // Considérer un appareil mobile si la largeur est inférieure à 768 pixels
const frustumSize = isMobile ? 14 : 8; // Utiliser un frustum plus petit pour les appareils mobiles


// Créer une caméra orthographique
const camera = new THREE.OrthographicCamera(
    frustumSize * aspect / -2, 
    frustumSize * aspect / 2, 
    frustumSize / 2, 
    frustumSize / -2, 
    0.1, 
    100
);

// Positionner la caméra
camera.position.x = -4;
camera.position.y = 4;
camera.position.z = 5;

// Orienter la caméra vers le centre de la scène
camera.lookAt(0, 1, 0);
camera.updateProjectionMatrix();

// Ajouter la caméra à la scène
scene.add(camera);


window.addEventListener('resize', () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Mettre à jour l'aspect et les limites du frustum de la caméra orthographique
    const newAspect = sizes.width / sizes.height;
    camera.left = frustumSize * newAspect / -2;
    camera.right = frustumSize * newAspect / 2;
    camera.top = frustumSize / 2;
    camera.bottom = frustumSize / -2;
    camera.updateProjectionMatrix();

    // Mettre à jour le rendu
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

const isMobile2 = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isUserAgentMobile = /android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream;
    const isScreenSizeMobile = window.innerWidth <= 800 && window.innerHeight <= 600;

    return isUserAgentMobile || isScreenSizeMobile;
}

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setClearColor(0x000000, 0); 
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Variables pour stocker la position de la souris
let mouseX = window.innerWidth / 2;
let mouseY = window.innerHeight / 2;

// Variables pour la position cible de la caméra
let targetX = -4;
let targetY = 4;

// Écouteur d'événements pour la souris (bureau)
if (!isMobile2()) {
    window.addEventListener('mousemove', (event) => {
        // Normaliser les coordonnées de la souris entre -1 et 1
        mouseX = (event.clientX / window.innerWidth) * 2 - 1;
        mouseY = -(event.clientY / window.innerHeight) * 2 + 1;

        // Calculer la position cible de la caméra
        targetX = mouseX * 1 - 4; // Ajustez le facteur de multiplication selon vos besoins
        targetY = mouseY * 1 + 4; // Ajustez le facteur de multiplication selon vos besoins
    });
}

// Variables pour le drag and drop (mobile)
let isDragging = false;
let startX = 0;
let startY = 0;

// Écouteurs d'événements pour le drag and drop (mobile)
if (isMobile2()) {
    console.log("drag")
    window.addEventListener('touchstart', (event) => {
        isDragging = true;
        startX = event.touches[0].clientX;
        startY = event.touches[0].clientY;
    });

    window.addEventListener('touchmove', (event) => {
        if (isDragging) {
            // Normaliser les coordonnées de la touche entre -1 et 1
            const currentX = event.touches[0].clientX;
            const currentY = event.touches[0].clientY;

            const deltaX = currentX - startX;
            const deltaY = currentY - startY;

            // Calculer la position cible de la caméra
            targetX -= deltaX * 0.01; // Ajustez le facteur de multiplication selon vos besoins
            targetY += deltaY * 0.01; // Ajustez le facteur de multiplication selon vos besoins

            targetX = Math.max(Math.min(targetX,-3),-5)
            targetY = Math.max(Math.min(targetY,5),3)

            startX = currentX;
            startY = currentY;
        }
    });

    window.addEventListener('touchend', () => {
        isDragging = false;
    });
}


const points = [
    {
        position: new THREE.Vector3(-3, 1.8, 2.2),
        element: document.querySelector('.point-0')
    },
    {
        position: new THREE.Vector3(1.2, 1.8, 2.2),
        element: document.querySelector('.point-1')
    }
]

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
     // Interpolation linéaire (lerp) pour lisser les mouvements de la caméra
    const lerpFactor = 0.1; // Ajustez ce facteur pour un lissage plus rapide ou plus lent
    camera.position.x += (targetX - camera.position.x) * lerpFactor;
    camera.position.y += (targetY - camera.position.y) * lerpFactor;

 
     // Assurez-vous que la caméra regarde toujours vers le centre de la scène
     camera.lookAt(0, 1, 0);

     for(const point of points)
        {
            const screenPosition = point.position.clone()
            screenPosition.project(camera)
    
            const translateX = screenPosition.x * sizes.width * 0.5
            const translateY = - screenPosition.y * sizes.height * 0.5
            point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
        }

    const delta = clock.getDelta();

    mixers.forEach((mixer) => {
        mixer.update(delta);
    })

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()